import React, { useEffect, useRef, useState } from 'react';
import ArrowDownSVG from '../../styles/svg/ArrowDown.svg';
import { Wrapper } from '../../styles/Wrapper.style';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledImageHeroBannerFW, StyledImageHeroBannerSplit } from './ImageHeroBanner.style';

export const ImageHeroBanner: React.FC<ImageHeroBannerProps> = ({ image, title, scrollTitle, useH1 = true, category, layout = "full-width", smallDescription, onScrollClick }) => {
  //#region Hooks / Lifecycles
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<string | number>("100%");

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const handleContentResize = () => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }

  const init = (): void => {
    window.addEventListener("resize", handleContentResize);
    handleContentResize();
  }

  const destroy = (): void => {
    window.removeEventListener("resize", handleContentResize);
  }
  //#endregion

  //#region Templating
  const TitleTag = useH1 ? 'h1' : 'h2';

  switch (layout) {
    case "split":
      return (
        <StyledImageHeroBannerSplit
          data-cursor-color="var(--aqua)"
          titleLength={title.length}
          descriptionLength={smallDescription.length || 0}
          contentHeight={contentHeight}
        >
          <div className="image">

            <ShowOnScroll passive={true} delay={100}>
              <PactImage image={image} alt={title} />
            </ShowOnScroll>

          </div>

          <div className="container" data-cursor-size="0.2">

            <div className="banner-content" ref={contentRef}>

              {category && <ShowOnScroll className="category"><p>{category}</p></ShowOnScroll>}

              <ShowOnScroll>
                <TitleTag className="title">{title}</TitleTag>
              </ShowOnScroll>

              <ShowOnScroll className="small-desc">
                <p>{smallDescription}</p>
              </ShowOnScroll>


              {scrollTitle && (
                <ShowOnScroll
                  delay={150}
                  observerOptions={{
                    rootMargin: '100px 0px',
                  }}
                  className="scroll-container">
                  <button type="button" className="scroll" onClick={onScrollClick}>
                    <ArrowDownSVG />
                    <span className="scroll-text">
                      {scrollTitle}
                    </span>
                  </button>
                </ShowOnScroll>
              )}
            </div>

          </div>

        </StyledImageHeroBannerSplit>
      );
    case "full-width":
    default:
      return (
        <StyledImageHeroBannerFW data-cursor-color="var(--aqua)">
          <div className="bg">
            <ShowOnScroll passive={true} delay={100}>
              <PactImage image={image} alt={title} />
            </ShowOnScroll>
          </div>
          <Wrapper className='wrapper'>
            <ShowOnScroll>
              <TitleTag className='title'>{title}</TitleTag>
            </ShowOnScroll>

            {scrollTitle && (
              <ShowOnScroll delay={150} observerOptions={{
                rootMargin: '100px 0px'
              }}>
                <span className='scroll'><ArrowDownSVG />{scrollTitle}</span>
              </ShowOnScroll>
            )}
          </Wrapper>
        </StyledImageHeroBannerFW>
      )

  }
  //#endregion
}

interface ImageHeroBannerProps {
  image: any;
  title: string;
  scrollTitle?: string;
  useH1?: boolean;
  category?: string;
  smallDescription?: string;
  layout?: "split" | "full-width";
  onScrollClick?: (e: React.MouseEvent) => void;
}