import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { desktopHover, keepRatio, pxtorem, pxtovw } from '../../utils/Tools';
import { StyledPactImage } from '../PactImage/PactImage.styled';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';

export const StyledCaseStudyPreview = styled.div`
  ${keepRatio('1920/970')};
  margin-bottom: 100px;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden;

    &, ${StyledShowOnScroll}, ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 520px;
      width: 100%;
      background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, #2C2C2C 100%);
      z-index: 1;
    }

    ${StyledShowOnScroll}{
      transition: opacity 5s cubic-bezier(.22, 1, .36, 1), transform 5s cubic-bezier(.22, 1, .36, 1);
      opacity: 0;
      transform: scale(1.1);
      &.visible {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: end;

    ${desktopHover(`
      ${Wrapper} {
        opacity: 0.8;
      }
    `)}
  }

  ${Wrapper} {
    transition: opacity .25s;
    padding-top: ${pxtorem(140)};
    padding-bottom: ${pxtorem(140)};
  }

  .subtitle {
    font: 400 16px/20px var(--secondaryFont);
    text-transform: uppercase;
    display: block;
    margin-bottom: 20px;
  }

  .title {
    text-transform: uppercase;
    margin: 0;
    max-width: ${pxtovw(1204)};
    font: 300 max(6.25vw, 44px)/90% var(--primaryFont);
    @media (min-width: 1920px) {
      font: 300 120px/90% var(--primaryFont);
      max-width: 1204px;
    }
  }

  @media (max-width: 768px) {
    ${keepRatio('390/360')};

    ${Wrapper} {
      transition: opacity .25s;
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .bg {
      &:after {
        height: 50%;
      }
    }
  }
  
`;