import styled from 'styled-components';
import { Clamp, gearRatio, pxtorem, pxtovw, responsiveFontSizes } from '../../utils/Tools';
import { StyledPactImage } from '../PactImage/PactImage.styled';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';


export const StyledImageHeroBannerFW = styled.div`
  height: var(--window-height);
  position: relative;
  padding: var(--header-height) 0 ${pxtorem(80)};
  .bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--charcoal);
    overflow: hidden;
    &, ${StyledShowOnScroll}, ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }
    ${StyledShowOnScroll}{
      transition: opacity 5s cubic-bezier(.22, 1, .36, 1), transform 5s cubic-bezier(.22, 1, .36, 1);
      opacity: 0;
      transform: scale(1.1);
      &.visible {
        opacity: 1;
        transform: scale(1);
      }
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.2);
      z-index: 1;
    }
  }
  .wrapper {
    --color: var(--pebble);
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .title {
    text-transform: uppercase;
    max-width: ${pxtovw(1204)};
    font: 300 max(6.25vw, 44px)/90% var(--primaryFont);
    margin-bottom: max(${pxtovw(42)}, 24px);
    @media (min-width: 1920px) {
      max-width: 1204px;
      font: 300 120px/90% var(--primaryFont);
      margin-bottom: 42px;
    }
  }
  .scroll {
    text-transform: uppercase;
    font: 400 1rem/120% var(--secondaryFont);
    vertical-align: middle;
    svg {
      vertical-align: middle;
      width: 17px;
      height: 17px;
      display: inline-block;
      margin-right: 16px;
    }
  }
`

export const StyledImageHeroBannerSplit = styled.div<SplitBannerProps>`
  --mobileHeaderHeight:  92px;
  --contentHeight: ${({contentHeight}) => contentHeight}px;
  min-height: 100vh; // min-height is what determines when the content div starts, so if I calculate the height of the image, header, and container, we should be gucci on mobile + desktop
  position: relative;
  padding: var(--header-height) 0 0;
  background-color: var(--charcoal);

  .image {
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
    width: 50%;

    &,
    ${StyledShowOnScroll}, ${StyledPactImage}, .gatsby-image-wrapper {
      height: 100%;
    }

    ${StyledShowOnScroll} {
      transition: opacity 5s cubic-bezier(0.22, 1, 0.36, 1),
        transform 5s cubic-bezier(0.22, 1, 0.36, 1);
      opacity: 0;
      transform: scale(1.1);
      &.visible {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      z-index: 1;
    }
  }

  .container {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    right: 0;
    background-color: var(--charcoal);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .title {
    text-transform: uppercase; 
    font: 300 ${({titleLength}) => responsiveFontSizes({contentLength: titleLength, maxFontSize: 56, minFontSize: 24, cutoffLength: 30})} / 120% var(--primaryFont);
   }

  .title, .small-desc, .category {
    color: var(--white);
    p {
      color: inherit;
    }
  }

  .title, .category {
    margin: 0 0 max(${pxtovw(40)}, 40px);
    p {
      margin: 0;
    }
  }

  .scroll-container {
    position: relative;
    transform: translateY(350%);
  }

  .scroll {
    transition: color .25s;
    vertical-align: middle;
    svg {
      vertical-align: middle;
      width: 17px;
      height: 17px;
      display: inline-block;
      margin-right: 16px;
    }
  }

  .category,
  .scroll {
    text-transform: uppercase;
    font: 400 16px / 120% var(--secondaryFont);
  }

  .banner-content {
    height: auto;
    position: relative;
    --width: 746px;
    max-width: calc(var(--width) + var(--gutter) * 2);
    padding: var(--gutter); 
  }

  .small-desc {
    p {
      max-width: 517px;
      margin: 0;
      color: var(--pebble);
      font: 300 1rem / 150% var(--secondaryFont);
    }
  }

  .scroll {
    --textColor: #CFCDCB;
    background: transparent;
    border: none;
    cursor: none;
    color: var(--textColor);
    padding: 0;

    .scroll-text {
      color: inherit;
      overflow: hidden;
      position: relative;
      top: 1px;
    }

    .scroll-text:after {
      content: '';
      position: absolute;
      left: 0%;
      transform: scaleX(0);
      transform-origin: right center;
      bottom: 0px;
      width: 100%;
      height: 1px;
      background: currentColor;
      transition: transform 1s cubic-bezier(.22,1,.36,1);
    }

    svg path {
      transition: fill 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    }
  }

  .scroll:hover {
    color: var(--aqua);

    .scroll-text:after {
      transform: scaleX(1);
      transform-origin: left center;
    } 
  }

  @media (max-width: 900px) {
    --imageHeight: 35vh;
    min-height: calc(var(--imageHeight) + var(--mobileHeaderHeight) + var(--contentHeight));

    .container {
      height: calc(var(--contentHeight) + ${pxtorem(64)});
      width: 100%;
      top: calc(var(--imageHeight) + 2rem);
      justify-content: flex-start;
    }
    
    .image {
      width: 100%;
      height: var(--imageHeight);
    }

    .title {
      font: 300 ${({titleLength}) => responsiveFontSizes({contentLength: titleLength, maxFontSize: 48, minFontSize: 28, cutoffLength: 25})} / 120% var(--primaryFont);
    }
  }

  @media (max-width: 768px) {
    .scroll, .category {
      font-size: 12px;
    }

    .title {
      font-size: ${({titleLength}) => responsiveFontSizes({contentLength: titleLength, maxFontSize: 32, minFontSize: 28, cutoffLength: 32})};
    }

    .title, .category {
      margin-bottom: max(${pxtovw(40)}, 40px);
      p {
        margin: 0;
      }
    }
    
    .small-desc p {
      font: 400 ${({ descriptionLength }) => responsiveFontSizes({contentLength: descriptionLength, maxFontSize: 20, minFontSize: 16, cutoffLength: 50})} / 150% var(--secondaryFont);  
    } 
  }
`;


interface SplitBannerProps {
  titleLength: number;
  descriptionLength: number;
  contentHeight?: number | string;
}
