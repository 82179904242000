import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { generateReactFromMarkdown, removeNewLineFromReactComponent } from '../cms/cms-components';
import { Page } from '../components/Page';
import { Section } from '../styles/Section.style';
import { getLocaleMdData } from '../utils/Tools';
import { ImageHeroBanner } from '../components/ImageHeroBanner/ImageHeroBanner';
import { CaseStudyPreview, CaseStudyPreviewProps } from '../components/CaseStudyPreview/CaseStudyPreview';
import themeObj from '../styles/theme';
import { SEO, SEOProps } from '../components/SEO/SEO';
import { StyledCaseStudy } from './case-study.style';

const CaseStudy = ({ data, pageContext }: CaseStudyProps) => {
  // const themeContext = useContext(ThemeContext);

  const _data = getLocaleMdData(data);
  const { language, slugs } = pageContext;
  const linkedImages = data.markdownRemark?.frontmatter.linkedFiles ?? [];
  const relatedStudy: CaseStudyPreviewProps = data.related.nodes[0]?.fields?.locales[language];


  const {
    theme,
    seo,
    content,
    featuredImage,
    client
  } = _data[language];

  const hasFrenchContent = _data['fr'].content !== _data['en'].content;

  const globalSEO: SEOProps = (data as any).globalSEO?.fields.locales[language].seo[0] || {};

  const { title: seoTitle, description, ogImage } = (seo ? seo[0] : null) || {};

  const title = language === 'en' ? _data.en.title : _data.fr.titleFR;

  const cursorColor = themeObj[theme || 'pebble'].cursor;

  return (
    <Page background="charcoal" language={language} slugs={slugs} showMissingFrenchBanner={language === 'fr' && !hasFrenchContent} overlapHeader>
      <StyledCaseStudy>
        <SEO
          title={seoTitle ? seoTitle : `${client} - ${title}`}
          description={description ? description : globalSEO.description}
          ogImage={ogImage ? ogImage : globalSEO.ogImage} />
        <Section className={`theme-${theme || 'pebble'}`} bg={theme || 'pebble'} data-cursor-color={cursorColor}>
          <ImageHeroBanner title={title} image={featuredImage} scrollTitle={client} />
          <div className="content">
            <ReactMarkdown
              components={{ ...generateReactFromMarkdown(linkedImages) }}
              // transformImageUri={(src: string) => isPreview ? src.split('../static/').join('/') : src}
              rehypePlugins={[rehypeRaw]}>{removeNewLineFromReactComponent(content)}</ReactMarkdown>
          </div>
        </Section>
        {relatedStudy && (
          <CaseStudyPreview {...relatedStudy} />
        )}
      </StyledCaseStudy>
    </Page>
  )
}

export const pageQuery = graphql`
  query ($id: String!, $relatedCaseStudy: String) {
    globalSEO: markdownRemark(fileAbsolutePath: {regex: "/misc/seo.md/"}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
          }
          fr {
            ...SeoFragmentFR
          }
        }
      }
    }
    markdownRemark(id: {eq: $id}) {
      fields {
        locales {
          en {
            ...SeoFragmentEN
            theme
            slug
            title
            titleFR
            client
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 3840
                  placeholder: DOMINANT_COLOR
                  transformOptions: {fit: COVER}
                )
              }
            }
            content
          }
          fr {
            ...SeoFragmentFR
            theme
            slug
            title
            titleFR
            client
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 3840
                  placeholder: DOMINANT_COLOR
                  transformOptions: {fit: COVER}
                )
              }
            }
            content
          }
        }
      }
      frontmatter {
        linkedFiles {
          relativePath
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
          imageGrid: childImageSharp {
            gatsbyImageData(
              width: 3326
              placeholder: BLURRED
            )
          }
          quoteBanner: childImageSharp {
            gatsbyImageData(
              width: 118
              placeholder: BLURRED
            )
          }
          sideBySide: childImageSharp {
            gatsbyImageData(
              width: 1640
              placeholder: BLURRED
              transformOptions: {fit: COVER}
            )
          },
          fullWidthCarousel: childImageSharp {
            gatsbyImageData(
              width: 2408
              placeholder: DOMINANT_COLOR
              transformOptions: {fit: COVER}
            )
          },
          logoListing: childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }

    related: allMarkdownRemark(limit: 1, filter: {frontmatter: {title: {eq: $relatedCaseStudy}}}) {
      nodes {
        fields {
          locales {
            en {
              title
              client
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 3840
                    placeholder: DOMINANT_COLOR
                    transformOptions: {fit: COVER}
                  )
                } 
              }
            }
            fr {
              title: titleFR
              client
              slug
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 3840
                    placeholder: DOMINANT_COLOR
                    transformOptions: {fit: COVER}
                  )
                } 
              }
            }
          }
        }
      }
    }
  }
`

interface CaseStudyProps {
  pageContext: {
    id: string;
    language: lang;
    slugs: { en: string, fr: string };
  };
  data: LocalizedAllMarkdownDataRelation<CaseStudyData, CaseStudyPreviewProps>['data']
}

interface CaseStudyData {
  theme: ThemeBG;
  seo: SEOProps[];
  slug: string;
  title: string;
  titleFR: string;
  client: string;
  featuredImage: any;
  content: string;
  linkedFiles: any[];
}
interface RelatedCaseStudyData {
  slug: string;
  title: string;
  client: string;
  featuredImage: any;
}


export default CaseStudy;