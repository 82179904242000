import { Link } from 'gatsby';
import React, { useEffect, useContext } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { paths } from '../../utils/paths';
import { PactContext } from '../PactContext';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';
import { StyledCaseStudyPreview } from './CaseStudyPreview.style';

export const CaseStudyPreview: React.FC<CaseStudyPreviewProps> = ({ title, client, slug, featuredImage }) => {
  //#region Hooks / Lifecycles
  const { language } = useContext(PactContext);
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }
  //#endregion

  //#region Templating
  return (
    <StyledCaseStudyPreview>
      <Link className="link" to={`${paths[language].prefix === '' ? '' : `/${paths[language].prefix}`}/${paths[language].caseStudies}/${slug}`}>
        <div className="bg">
          <ShowOnScroll passive>
            <PactImage image={featuredImage} alt={title} />
          </ShowOnScroll>
        </div>
        <Wrapper>
          <ShowOnScroll><span className="subtitle">{language === 'en' ? 'Next Up' : 'Prochain'} – {client}</span></ShowOnScroll>
          <ShowOnScroll delay={150}>
            <h2 className="title">{title}</h2>
          </ShowOnScroll>
        </Wrapper>
      </Link>
    </StyledCaseStudyPreview>
  )
  //#endregion
}

export interface CaseStudyPreviewProps {
  title: string;
  client: string;
  slug: string;
  featuredImage: any;
}