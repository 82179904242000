import styled from 'styled-components';
import { pxtorem } from '../utils/Tools';

export const StyledCaseStudy = styled.div`
  .content {
    padding-bottom: ${pxtorem(120)};

    & > div:last-child > div {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
  }
`